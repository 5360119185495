import SectionMessage from "@atlaskit/section-message";

export default function Home() {
  return (
    <div>
      <SectionMessage appearance="information">
        <p>
          本应用支持将您的“华为健康”中的跑步数据含运动时间、步频、GPS、配速、心率等导入到“Strava”.
          本应用仅做转换后将记录上传，不做任何记录，数据不做任何其他用途。
        </p>
        <p>
          您可随时取消“华为Health Kit”授权， 详情见
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a href="#">授权管理</a>。
        </p>
      </SectionMessage>
    </div>
  );
}
