
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`隐私说明`}</h1>
    <p>{`生效日期: 2021年09月12日`}</p>
    <h2>{`Spark 收集的信息`}</h2>
    <h3>{`基本信息`}</h3>
    <ul>
      <li parentName="ul">{`邮箱：用做您的用户标识。`}</li>
      <li parentName="ul">{`IP、浏览器UA等信息：用于区分您访问本站点的设备版本。`}</li>
    </ul>
    <h3>{`运动记录`}</h3>
    <p>{`运动记录信息本应用不做存储，接受到华为Health Kit回调后直接转化，并转换为Strava可以识别的标准格式。在上传到Strava应用之前，本服务可能会临时缓存用于确保上传成功，上传成功后会删除数据。 本应用会接收到如下数据并做转换处理。`}</p>
    <ul>
      <li parentName="ul">{`位置： 用于跑步路线可视化。`}</li>
      <li parentName="ul">{`心率：用于对跑步中运动强度给出提醒和建议。`}</li>
      <li parentName="ul">{`跑步距离： 用于跑步路线可视化。`}</li>
      <li parentName="ul">{`跑步时间：用于跑步记录日志。`}</li>
    </ul>
    <h2>{`数据处理策略和声明`}</h2>
    <ul>
      <li parentName="ul">{`本应用承诺不对任何除用户授权外的实体做任何数据共享。`}</li>
      <li parentName="ul">{`本应用承诺运动记录数据仅做技术转换使用，在必要的技术处理后会删除。`}</li>
    </ul>
    <p>{`Strava 保留随时修改本《隐私政策》的权利。请不时查看一下。如果 Strava 对本《隐私政策》进行变更，将会及时在 Services 中发布更新的《隐私政策》。如果我们进行重大变更，我们将会发出醒目的通知。如果您反对《隐私政策》的任何变更，您应该停止使用 Services 并删除您的帐户。`}</p>
    <p>{`2021年09月12日`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;