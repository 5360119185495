import "./App.css";
import {
  AtlassianNavigation,
  PrimaryButton,
  generateTheme,
} from "@atlaskit/atlassian-navigation";
import { AtlassianIcon } from "@atlaskit/logo";

import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import { Home, Agreement, Privacy } from "./pages";

const AtlassianHome = () => (
  <span>
    <AtlassianIcon></AtlassianIcon>
  </span>
);

const theme = generateTheme({
  name: "high-contrast",
  backgroundColor: "#272727",
  highlightColor: "#E94E34",
});

function App() {
  return (
    <>
      <Router>
        <AtlassianNavigation
          theme={theme}
          renderProductHome={AtlassianHome}
          primaryItems={[
            <Link to="/privacy">
              <PrimaryButton>Spark</PrimaryButton>
            </Link>,
          ]}
        />
        <div
          style={{
            padding: "32px",
            textAlign: "justify",
          }}
        >
          <Switch>
            <Route path="/agreement">
              <Agreement />
            </Route>
            <Route path="/privacy">
              <Privacy />
            </Route>
            <Route path="/">
              <Home />
            </Route>
          </Switch>
        </div>

        <div
          style={{
            textAlign: "center",
          }}
        >
          <Link to="/privacy">隐私声明</Link> |{" "}
          <Link to="/agreement">用户协议</Link>
        </div>
      </Router>
    </>
  );
}

export default App;
