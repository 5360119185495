// import SectionMessage, {
//   SectionMessageAction,
// } from "@atlaskit/section-message";
// import InlineMessage from "@atlaskit/inline-message";
/* eslint-disable import/no-webpack-loader-syntax */
import Content from "!babel-loader!@mdx-js/loader!../docs/privacy.mdx";

export default function Privacy() {
  return <Content />;
}
