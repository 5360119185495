
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`服务条款`}</h1>
    <p>{`生效日期： 2021年09月12日`}</p>
    <h2>{`感谢你使用 Spark`}</h2>
    <p>{`「Spark」是指通过合法拥有并运营的、包括但不限于 Spark（以下可能会称为 “Spark”）。Spark 服务以及该服务所包括的各种业务功能统称为“我们的产品和/或服务”。
《Spark用户协议》（以下简称“本协议”）是用户与我们就下载、安装、复制、使用我们的产品和/或服务订立的契约。请在开始使用我们的产品和/或服务之前，认真阅读并充分理解本服务协议。
如同意本协议，或完成全部注册/登录程序，即表示已充分阅读、理解并接受本协议的全部内容，并与我们之间达成一致，成为 Spark 用户；如果不同意本协议的任意内容，或者无法准确理解本协议条款的解释，请不要对相关条款内容对应的功能进行任何后续操作，否则，使用及任何操作行为，均视为已接受本协议约束。
使用 Spark 产品及服务将视为您同意以下使用条款。`}</p>
    <h2>{`1、仅供个人使用`}</h2>
    <p>{`Spark 仅供用户个人用于非商业用途。如果要销售 Spark 或者与 Spark 有关或派生的任何资料、服务或软件，则必须得到Spark团队的允许。如果用户对 Spark 有任何意见，或有如何改进的建议，可向我们提出。`}</p>
    <h2>{`2、知识产权`}</h2>
    <p>{`用户承认Spark团队拥有对 Spark 的所有权利，包括但不限于所有知识产权。“知识产权”指在专利法、版权法、商业机密法、商标法、反不正当竞争法等法中规定的所有权利以及其中的所有应用、更新、扩展和恢复，无论在现在还是以后在全球范围内实施和生效。用户同意不会修改、改编、翻译 Spark、创作 Spark 的派生作品、通过反编译、反向工程、反汇编或其它方式从Spark 得到源代码。用户同意不会删除、掩盖或更改 Spark 公司或任何第三方的版权声明、商标或其它所有权声明。`}</p>
    <h2>{`3、用户注意事项`}</h2>
    <h3>{`3.1、软件使用规范`}</h3>
    <ul>
      <li parentName="ul">{`用户应当对其使用本软件及相关服务的行为负责，除非法律允许或者经公司事先书面许可，你使用 Spark 软件及相关服务不得具有下列行为：`}</li>
      <li parentName="ul">{`使用未经公司授权或许可的任何插件、外挂、系统或第三方工具对 Spark 软件及相关服务的正常运行进行干扰、破坏、修改或施加其他影响。`}</li>
      <li parentName="ul">{`利用或针对 Spark 软件及相关服务进行任何危害计算机网络安全的行为，包括但不限于：`}</li>
      <li parentName="ul">{`非法侵入他人网络、干扰他人网络正常功能、窃取网络数据等危害网络安全的活动；`}</li>
      <li parentName="ul">{`提供专门用于从事侵入网络、干扰网络正常功能及防护措施、窃取网络数据等危害网络安全活动的程序、工具；`}</li>
      <li parentName="ul">{`明知他人从事危害网络安全的活动的，为其提供技术支持、广告推广、支付结算等帮助；`}</li>
      <li parentName="ul">{`使用未经许可的数据或进入未经许可的服务器/账号；`}</li>
      <li parentName="ul">{`未经允许进入公众计算机网络或者他人计算机系统并删除、修改、增加存储信息；`}</li>
      <li parentName="ul">{`未经许可，企图探查、扫描、测试“便签”系统或网络的弱点或其它实施破坏网络安全的行为；`}</li>
      <li parentName="ul">{`企图干涉、破坏 Spark 系统或网站的正常运行，故意传播恶意程序或病毒以及其他破坏干扰正常网络信息服务的行为；`}</li>
      <li parentName="ul">{`伪造 TCP/IP 数据包名称或部分名称；`}</li>
      <li parentName="ul">{`对 Spark 软件及相关服务进行反向工程、反向汇编、编译或者以其他方式尝试发现本软件的源代码；`}</li>
      <li parentName="ul">{`恶意注册 Spark 软件及相关服务的账号，包括但不限于频繁、批量注册账号；`}</li>
      <li parentName="ul">{`违反法律法规、本协议、公司的相关规则及侵犯他人合法权益的其他行为。`}</li>
      <li parentName="ul">{`如果Spark团队有理由认为你的行为违反或可能违反上述约定的，公司可独立进行判断并处理，且有权在不事先通知的情况下终止向你提供服务，并追究相关法律责任。`}</li>
    </ul>
    <h3>{`3.2、内容行为规范`}</h3>
    <p>{`使用 Spark 必须遵守国家法律法规，用户承诺绝对不会通过我们的产品和/或服务发送或传播违反国家法律法规及不文明的信息。否则，我们可对用户发布的信息依法或依本协议进行删除或屏蔽，并有权视情节采取警告、限期改正、暂停服务等措施。此类信息包括但不限于：`}</p>
    <ul>
      <li parentName="ul">{`反对宪法所确定的基本原则的；`}</li>
      <li parentName="ul">{`危害国家安全、泄露国家秘密、颠覆国家政权、破坏国家统一、损害国家荣誉和利益的；`}</li>
      <li parentName="ul">{`煽动民族仇恨、民族歧视，破坏民族团结的；`}</li>
      <li parentName="ul">{`破坏国家宗教政策，宣扬邪教和封建迷信的；`}</li>
      <li parentName="ul">{`散布谣言，扰乱社会秩序，破坏社会稳定的；`}</li>
      <li parentName="ul">{`散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；`}</li>
      <li parentName="ul">{`恐吓、侮辱、攻击、诽谤他人，侵害他人合法权益的；`}</li>
      <li parentName="ul">{`利用本平台从事洗钱、窃取商业秘密、窃取个人信息等违法犯罪活动的；`}</li>
      <li parentName="ul">{`发布任何侵犯他人著作权、商标权等知识产权及其他合法权利的；`}</li>
      <li parentName="ul">{`侵害未成年人合法权益或者损害未成年人身心健康的；`}</li>
      <li parentName="ul">{`散布商业广告，或类似的商业招揽信息、过度营销信息及垃圾信息；`}</li>
      <li parentName="ul">{`含有其他违反法律法规、政策及公序良俗、干扰便签正常运营或侵犯其他你或第三方合法权益内容的其他信息。`}</li>
    </ul>
    <h2>{`4、用户隐私制度`}</h2>
    <p>{`尊重用户个人隐私是我们的一项基本政策。Spark团队一般不会公开、编辑或透露用户的注册资料或保存在Spark团队服务中的非公开内容，除非我们在诚信的基础上认为透露这些信息在以下几种情况是必要的：`}</p>
    <ul>
      <li parentName="ul">{`遵守有关法律规定，包括在国家有关机关查询时，提供用户通过 Spark 发布的信息内容及其发布时间、互联网地址或者域名。`}</li>
      <li parentName="ul">{`保持维护「Spark」公司的商标等知识产权。`}</li>
      <li parentName="ul">{`在紧急情况下竭力维护用户个人和社会大众的隐私安全。`}</li>
      <li parentName="ul">{`Spark团队认为必要的其他情况下。
要了解 Spark 数据保护措施的具体信息，请阅读 Spark 的隐私权政策，网址是： `}<a parentName="li" {...{
          "href": "https://spark.the520.cn/privacy%E3%80%82%E8%AF%A5%E6%94%BF%E7%AD%96%E8%A7%A3%E9%87%8A%E4%BA%86%E5%BD%93%E6%82%A8%E4%BD%BF%E7%94%A8"
        }}>{`https://spark.the520.cn/privacy。该政策解释了当您使用`}</a>{` Spark 的相关的服务时，Spark 会如何处理您的个人信息，以及如何保护您的隐私权。您同意会在遵守 Spark 隐私权政策的前提下应用自己的数据。`}</li>
    </ul>
    <h2>{`5、服务变更`}</h2>
    <p>{`Spark团队保留随时变更、中断或终止服务而不需通知用户的权利。用户接受Spark团队行使变更、中断或终止服务的权利，Spark团队不需对用户或第三方负责。 `}</p>
    <h2>{`6、责任限制`}</h2>
    <p>{`用户理解并同意自主选择下载和使用「Spark」和所有与「Spark」一同提供或通过「Spark」提供的第三方软件，风险自负，并且对于因下载或使用「Spark」和此类第三方软件而对计算机系统造成的损坏或数据的丢失，用户应承担全部责任。在适用法律允许的最大范围内，Spark团队明确表示不提供任何其他类型的保证，不论使明示的或默示的，包括但不限于适销性、适用性、可靠性、准确性、完整性、无病毒以及无错误的任何默示保证和责任。
在适用法律允许的最大范围内，Spark团队及其许可人不会向您做出以下声明或担保：`}</p>
    <ul>
      <li parentName="ul">{`您对服务的使用会满足您的需求；`}</li>
      <li parentName="ul">{`您对服务的使用会连续无中断、及时、安全或没有错误；`}</li>
      <li parentName="ul">{`您使用相关服务而获得的任何信息一律准确可靠；`}</li>
      <li parentName="ul">{`作为相关服务的一部分向您提供的任何软件所发生的操作或功能瑕疵将获得修正。
在适用法律允许的最大范围内，Spark团队不就因用户使用「Spark」引起的，或在任何方面与Spark团队的产品和服务有关的任何意外的、非直接的、特殊的、或间接的损害或请求（包括但不限于因人身伤害、因隐私泄漏、因未能履行包括诚信或合理谨慎在内的任何责任、因过失和因任何其他金钱上的损失或其他损失而造成的损害赔偿）承担任何责任。用户上载的行为，即意味着用户或用户代理的著作权人授权Spark团队及其关联公司在「Spark」的服务器上保存内容，但用户或原著作权人仍保有上载作品的著作权，且「Spark」公司未经用户或原著作权人许可不得将上载作品在其他公开渠道发布。如用户在使用「Spark」时涉及到其他Spark团队或其关联公司提供的服务，还应遵守相应的服务条款。 `}</li>
    </ul>
    <h2>{`7、保障`}</h2>
    <p>{`用户同意保障和维护仙蒂司的利益，负责支付由用户违反本服务条款或Spark团队其他服务条款而引起的律师费用、损害补偿费用、政府机关处罚费用和其它侵权赔偿费用等。`}</p>
    <h2>{`8、第三方产品和服务`}</h2>
    <p>{`本软件可能会使用第三方软件或技术（包括本软件可能使用的开源代码和公共领域代码等，下同），这种使用已经获得合法授权。
本软件如果使用了第三方的软件或技术，我们将按照相关法规或约定，对相关的协议或其他文件，可能通过本协议附件、在本软件安装包特定文件夹中打包等形式进行展示，它们可能会以“软件使用许可协议”、“授权协议”、“开源代码许可证”或其他形式来表达。前述通过各种形式展现的相关协议或其他文件，均是本协议不可分割的组成部分，与本协议具有同等的法律效力，你应当遵守这些要求。如果你没有遵守这些要求，该第三方或者国家机关可能会对你提起诉讼、罚款或采取其他制裁措施，并要求我们给予协助，你应当自行承担法律责任。
如因本软件使用的第三方软件或技术引发的任何纠纷，应由该第三方负责解决，我们不承担任何责任。我们不对第三方软件或技术提供客服支持，若你需要获取支持，请与第三方联系。 `}</p>
    <h2>{`9、法律及协议更新`}</h2>
    <p>{`根据国家法律法规变化及网站运营需要，我们有权对本协议条款不时地进行修改，并根据国家法律法规的要求进行公示，修改后的协议在公示后生效并代替原来的协议。你应不时关注并阅读最新版的协议及网站公告。如你不同意更新后的新协议，你有权立即停止使用我们的产品和/或服务。如你继续使用我们的产品和/或服务，即视为完全同意更新后的协议。如果本协议中任何一条被司法或行政机关判定废止、无效或因任何理由不可执行，该条应视为可分的且并不影响任何其余条款的有效性和可执行性。`}</p>
    <p>{`上述条款将受中华人民共和国法律的约束并依据其解释。如出现纠纷，用户和Spark团队一致同意将纠纷交由当地人民法院管辖。如用户对本服务条款内容有任何疑问，可发邮件至(`}<a parentName="p" {...{
        "href": "mailto:support@the520.cn"
      }}>{`support@the520.cn`}</a>{`)获取相关信息。`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;